import "intersection-observer";
import swiper from "./swiper";
import faq from "./faq";
import lazyload from "./dal-lazyload";
import AOS from "aos";
import "lazysizes";
import navigation from "./navigation";
import heroVideo from "./videoHero";

// import cookieBanner from "./cookie-banner";
// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder 

(function () {

	faq.init();

	lazyload.init();

	navigation.init();

	AOS.init();

	heroVideo.createVideoHero();

	//Init all swiper if there are some
	let allSwiper = document.querySelectorAll(".swiper-container");
	if (allSwiper.length) {
		allSwiper.forEach(function (swiperEle) {
			//Set swiper element and swiper options from data-attribute
			swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
		})
	}

	// remove comment if you want to add a cookie banner
	// cookieBanner.init();
	// var targList = document.getElementsByClassName("ytp-chrome-top");
	// if (targList) {
	// 	for (var x = 0; x < targList.length; x++) {
	// 		targList[x].style.display = "none";
	// 	}
	// }
})();