export default {
	_getWindowWidth() {
		let windowWidth = 0;

		if (typeof (window.innerWidth) == "number") {
			windowWidth = window.innerWidth;
		} else {
			if (document.documentElement && document.documentElement.clientWidth) {
				windowWidth = document.documentElement.clientWidth;
			}
			else {
				if (document.body && document.body.clientWidth) {
					windowWidth = document.body.clientWidth;
				}
			}
		}
		return windowWidth;
	},
};