import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import anime from "animejs";

export default {
    init() {

        //////////// Navigation
        const menu = document.getElementById("menu");
        const hamburger = document.getElementById("hamburger");
        const header = document.querySelector(".header-menu");
        const body = document.querySelector("body");


        // We listen to the resize event
        window.addEventListener("resize", () => {
            if (menu.classList.contains("open")) {
                menu.classList.remove("open");
                hamburger.classList.remove("close");
                header.classList.remove("open-menu");
                body.classList.remove("overflow-hidden");
                body.classList.remove("fixed");
            }
        });

        if (hamburger) {
            hamburger.addEventListener("click", function () {

                const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });

                // close menu
                if (menu.classList.contains("open")) {
                    menu.classList.remove("open");
                    hamburger.classList.remove("close");
                    header.classList.remove("open-menu");
                    body.classList.remove("overflow-hidden");
                    body.classList.remove("fixed");

                    timeline.add({
                        targets: "#menu",
                        opacity: 0,
                        complete: () => {
                            clearAllBodyScrollLocks();
                        },
                    });

                } else {
                    // open menu
                    enableBodyScroll(header);
                    menu.classList.add("open");
                    hamburger.classList.add("close");
                    header.classList.add("open-menu");
                    body.classList.add("overflow-hidden");
                    body.classList.add("fixed");

                    timeline.add({
                        targets: "#menu",
                        opacity: 1,
                        complete: () => {
                        },
                    });
                }
            });
        }

        window.onscroll = function () {
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                header.classList.add("md:bg-black");
                header.classList.add("animate-on-scroll");
            } else {
                header.classList.remove("md:bg-black");
                header.classList.remove("animate-on-scroll");
            }
        };
    },
}